import React from 'react';
import * as S from './References.styles';

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <br />
      <S.Text>
       ECAP+{' '}
        <a href="" rel="noreferrer">
          Program Management Platform{' '}<br />
        </a>
        in 2024 &copy;.{' '}
        <a href="" rel="noreferrer">
         Project Concern Zambia
        </a>
      </S.Text>
    </S.ReferencesWrapper>
  );
};
